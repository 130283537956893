
@import '~bootstrap/dist/css/bootstrap.min.css';

.app {
    font-family: 'Source Sans Variable', sans-serif;
    /* Your global styles for the entire app */
}



.app-header {
    position: fixed;
    box-sizing: border-box;
    height: 10vh;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: white; /* Example background color, customize as needed */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Example box shadow, customize as needed */
    z-index: 10; /* Ensure the header is above other elements */
}

.logo img {
    width: 250px;
    margin-left:30px;
 
}

.flex-item {
    margin-top: 7vh; /* Espa�amento entre itens */
}

.nav-bar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.nav-bar li {
    margin-right: 15px;
}

.app-main {
    margin-top: 10vh; /* Adjust this value based on your header height */
    /* Add styles for the main content area if needed */
}

.app-footer {
    /* Add styles for the footer if needed */
}


.breadcrumbs {
    padding: 10px 0;
    font-size: 14px;
}

.breadcrumb-item {
    color: #007bff;
    text-decoration: none;
}

.breadcrumb-item-active {
    color: #6c757d;
}





form *,
form *::before,
form *::after {
    color: #0069c0;
}


.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
    color: #ab3ed8;
}


.custom-placeholder:focus {
    color: #0069c0; /* Cor espec�fica para o placeholder */
    opacity: 1; /* Garantir que a opacidade seja mantida */
}

.custom-placeholder {
    color: #0069c0;
}


/* Estilo para telas maiores que 800px */
@media (min-width: 800px) {
    .nav-bar {
        flex-direction: row;
    }
}

/* Estilo para telas menores que 800px */
@media (max-width: 799px) {
    .nav-bar {
        flex-direction: column;
    }
}


/* Footer.css */

@media (max-width: 800px) {
    footer {
        flex-direction: column;
        align-items: center;
    }
}



@media (max-width: 800px) {
    heading {
        font-size: 48px;
    }
}
